<template>
    <uw-content title="回访">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.visitCreate.Open()">新建回访</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <el-input @input="TableSearch" v-model="search.number" clearable  size="mini" placeholder="回访编号"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <!-- 表内容 -->
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>

                    <!-- 回访编号 -->
                    <vxe-column min-width="160" field="number" title="回访编号" fixed="left" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" icon="el-icon-paperclip" @click="$refs.visitDrawer.Open(row.id)">{{ row.number }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 回访时间 -->
                    <vxe-column min-width="100" field="date" title="回访时间"></vxe-column>

                    <!-- 回访形式 -->
                    <vxe-column min-width="120" field="type" title="回访形式" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false">{{ row.type?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 客户名称 -->
                    <vxe-column min-width="160" field="customer" title="客户名称" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false" type="primary" @click="$refs.customerDrawer.Open(row.customer?.id)">{{ row.customer?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 联系人 -->
                    <vxe-column min-width="120" field="contacts" title="联系人" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false" type="primary" @click="$refs.contactsDrawer.Open(row.contacts?.id)">{{ row.contacts?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 合同编号 -->
                    <vxe-column min-width="250" field="contract" title="合同编号" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false" type="primary" @click="$refs.contractDrawer.Open(row.contract?.id)">{{ row.contract?.number + '-' }}{{ row.contract?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 客户满意度 -->
                    <vxe-column min-width="120" field="satisficing" title="客户满意度" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false">{{ row.satisficing?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 客户反馈 -->
                    <vxe-column min-width="160" field="remarks" title="客户反馈"></vxe-column>

                    <!-- 回访人 -->
                    <vxe-column min-width="90" field="user_admin" title="回访人">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-user" v-if="row.user_admin">{{ row.user_admin?.name }}</el-link>
                        </template>
                    </vxe-column>
                    
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <crm-visit-create ref="visitCreate" @onChange="TableSearch()" />
        <crm-visit-drawer ref="visitDrawer" @onChange="TableSearch()" />
        <crm-customer-drawer ref="customerDrawer" @onChange="TableSearch()" />
        <crm-contacts-drawer ref="contactsDrawer" @onChange="TableSearch()" />
        <crm-contract-drawer ref="contractDrawer" @onChange="TableSearch()" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            loading: false,

            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                number: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {
        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/crm/visit/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['user_admin', 'type', 'contract', 'customer', 'contacts', 'satisficing']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 移除数据
        |--------------------------------------------------
        |
        */

        TableEventDelete(id) {
            this.$http.post('/9api/crm/leads/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>